import { Coupon } from '@/types/domain/order'
import { TAX_RATE, TPOINT_RATE } from '@/const'

/**
 * 【カート金額の計算式】
 *
 * (税抜価格：101円)
 * 税込価格：101 * 1.08 = 109.08 ≒ 110円
 * 配達手数料：100円
 * 支払手数料：100円
 * クーポン利用：30円
 * Vポイント利用：20円
 *
 * 合計金額：110 + 100 + 100 - 30 - 20 = 260円
 * Vポイント付与対象金額：(110 - 30 - 20) / 1.08 = 55.555555556 ≒ 56円 (ceil)
 */

export interface Payment {
  code: 'credit' | 'paidy' | 'np_wiz' | 'tpoint' | 'bank' | 'cod' | 'paypay'
  feeAmount: number
}

export interface Shipment {
  code: 'ikkatsu' | 'mailbin' | 'takuhai' | 'cod' | 'download'
  feeAmount: number
}

export interface IIkkatsu {
  caption1: string
  caption2: string | null
  caption3: string | null
  caption4: string | null
}

export interface ISelflabo {
  caption1: string
  caption2: string | null
  caption3: string | null
  caption4: string | null
}

export interface ICartProps {
  subtotal: number
  isDownloadable: boolean
  isPrintable: boolean
  isMailbinAvailable: boolean
  payments: Payment[]
  shipments: Shipment[]
  ikkatsu: IIkkatsu | null
  selflabo: ISelflabo | null
  checkSum: string
  downloadSetCartItemIds: number[]
}

export default class CartEntity {
  private _props: ICartProps

  constructor(props: ICartProps) {
    this._props = props
  }

  get props() {
    return this._props
  }

  get isIkkatsu(): boolean {
    return !!this._props.ikkatsu
  }

  get isSelflabo(): boolean {
    return !!this._props.selflabo
  }

  get isDownloadOnly(): boolean {
    return this._props.isDownloadable && !this._props.isPrintable
  }

  // 計算式は上述のコメントを参照
  // 対象金額がマイナスになるときは0を返す
  getTpointTargetPrice(params: { couponDiscountAmount: number; tpoint: number }): number {
    const { couponDiscountAmount, tpoint } = params
    const { subtotal } = this._props
    const taxIncluded = subtotal - couponDiscountAmount - tpoint
    const targetPrice = Math.ceil(taxIncluded / (1 + TAX_RATE))
    return targetPrice < 0 ? 0 : targetPrice
  }

  // 計算式は上述のコメントを参照
  getEstimateTpointAmount(params: { couponDiscountAmount: number; tpoint: number }): number {
    const targetPrice = this.getTpointTargetPrice(params)
    const estimateTpointAmount = Math.floor(targetPrice / TPOINT_RATE)
    return estimateTpointAmount
  }

  getPayment(code: Payment['code'] | null): Payment | null {
    if (!code) return null

    return this._props.payments.find(payment => payment.code === code) || null
  }

  getShipment(code: Shipment['code'] | null): Shipment | null {
    if (!code) return null

    return this._props.shipments.find(shipment => shipment.code === code) || null
  }

  // カート小計 + 配送手数料
  getSubtotalWithShipment(code: Shipment['code'] | null) {
    const { subtotal } = this._props
    const shipment = this.getShipment(code)
    return shipment ? shipment.feeAmount + subtotal : subtotal
  }

  // Deprecated
  getDiscountAmount(coupon?: Coupon): number {
    if (!coupon) return 0

    const { type, amount } = coupon

    if (type === 'DISCOUNT') {
      return amount
    } else if (type === 'DISCOUNT_PER') {
      // DISCOUNT_PERはdeprected
      // TODO: Send error log
      return Math.floor(this._props.subtotal * (amount / 100))
    }

    throw new Error('Invalid coupon type!')
  }

  getRawCouponDiscountAmount(coupon: Coupon | null) {
    return this.getDiscountAmount(coupon || undefined)
  }

  // カート合計金額を超えた割引は不可能なので、実際に適用される金額を返す
  getApplicableCouponDiscountAmount(params: { coupon: Coupon | null; shipmentCode: Shipment['code'] | null }): number {
    const { coupon, shipmentCode } = params
    // 必ず発生するのは小計 + 配送手数料
    const subtotalWishShipment = this.getSubtotalWithShipment(shipmentCode)
    const rawDiscountAmount = this.getRawCouponDiscountAmount(coupon)

    return subtotalWishShipment <= rawDiscountAmount ? subtotalWishShipment : rawDiscountAmount
  }

  // 小計 + 配送手数料にクーポンを適用した金額。
  // 支払い方法選択判定、Vポイント適用金額判定等に利用される
  getBillingBaseAmount(params: { coupon: Coupon | null; shipmentCode: Shipment['code'] | null }): number {
    const { coupon, shipmentCode } = params
    const subtotalWishShipment = this.getSubtotalWithShipment(shipmentCode)
    const applicableCouponDiscountAmount = this.getApplicableCouponDiscountAmount({ coupon, shipmentCode })
    const billingBaseAmount = subtotalWishShipment - applicableCouponDiscountAmount

    return billingBaseAmount
  }

  // 小計とクーポンを適用した金額。
  getSubtotalCouponAmount(params: { coupon: Coupon | null; shipmentCode: Shipment['code'] | null }): number {
    const { coupon, shipmentCode } = params
    const { subtotal } = this._props
    const applicableCouponDiscountAmount = this.getApplicableCouponDiscountAmount({ coupon, shipmentCode })
    const subtotalCouponAmount = subtotal - applicableCouponDiscountAmount

    return subtotalCouponAmount
  }

  // 1度の注文で利用できるVポイントの上限。Vポイント割引よりクーポン割引が優先される
  getApplicableTpointLimit(params: { tpointAmount: number; coupon: Coupon | null; shipmentCode: Shipment['code'] | null }): number {
    const { tpointAmount, coupon, shipmentCode } = params

    // Vポイントを利用する必要があるかどうか(クーポン適用額 < 小計と配送手数料の合算値であること)を確認
    const billingBaseAmount = this.getBillingBaseAmount({ coupon, shipmentCode })

    if (billingBaseAmount <= 0) return 0

    return tpointAmount < billingBaseAmount ? tpointAmount : billingBaseAmount
  }

  // 計算式は上述のコメントを参照
  getTotalPrice(params: { shipmentCode: Shipment['code'] | null; paymentCode: Payment['code'] | null; couponDiscountAmount: number; tpoint: number }): number {
    const { shipmentCode, paymentCode, couponDiscountAmount, tpoint } = params

    const shipment = this.getShipment(shipmentCode)
    const payment = this.getPayment(paymentCode)
    const shipmentFeeAmount = shipment ? shipment.feeAmount : 0
    const paymentFeeAmount = payment ? payment.feeAmount : 0

    // クーポン割引適用額が0円を下回らないかチェック
    const totalWithoutCoupon = this._props.subtotal + shipmentFeeAmount + paymentFeeAmount - tpoint
    const actualCouponDiscountAmount = totalWithoutCoupon >= couponDiscountAmount ? couponDiscountAmount : totalWithoutCoupon

    return totalWithoutCoupon - actualCouponDiscountAmount
  }

  getPaymentCandidates(params: { toStrangerAddress: boolean; shipmentCode: Shipment['code'] | null; useTpoint: number; coupon: Coupon | null }): Payment[] {
    const { shipmentCode, coupon, useTpoint, toStrangerAddress } = params
    const { payments, isDownloadable } = this._props
    const flags: { [key in Payment['code']]: boolean } = {
      credit: true,
      paidy: false, // deprecated
      np_wiz: true,
      tpoint: false, // 全額Vポイント支払いのときにのみ利用。候補しては表示しない
      bank: false, // deprecated
      cod: true,
      paypay: true
    }

    const subtotalCouponAmount = this.getSubtotalCouponAmount({ coupon, shipmentCode })
    const withTpointDiscount = subtotalCouponAmount - useTpoint

    /*
     * 合計金額チェック
     * - 合計金額が100,000より高い場合は「NP後払い」は不可 （こちらの条件を出す時に配送方法は「宅配便」のみで、メール便はありえない想定。）
     *   - 100000 - NP手数料npWiz.feeAmount - 宅配便takuhai.feeAmount
     * - 合計金額が300,000より高い場合は「代金引換」は不可
     */
    const npWiz = this.getPayment('np_wiz')
    const cod = this.getPayment('cod')
    const takuhai = this.getShipment('takuhai')
    const npWizLimit = 100000 - (npWiz ? npWiz.feeAmount : 0) - (takuhai ? takuhai.feeAmount : 0)
    const codLimit = 300000 - (cod ? cod.feeAmount : 0) - (takuhai ? takuhai.feeAmount : 0)

    if (withTpointDiscount >= npWizLimit) {
      flags.np_wiz = false
    }

    if (withTpointDiscount >= codLimit) {
      flags.cod = false
    }

    /**
     * 購入商品種別チェック
     * - ダウンロード商品が含まれている場合は「NP後払い」と「代金引換」は不可
     */
    if (isDownloadable) {
      flags.np_wiz = false
      flags.cod = false
    }

    /**
     * 配送種別チェック
     * - 一括配送・スナ貸しの場合は「代金引換」は不可
     * - デフォルトお届け先以外に配送する場合は「NP後払い」と「代金引換」は不可
     */
    if (this.isIkkatsu) {
      flags.cod = false
    }

    if (this.isSelflabo) {
      flags.cod = false
    }

    if (toStrangerAddress) {
      flags.np_wiz = false
      flags.cod = false
    }

    return payments.filter(payment => flags[payment.code])
  }

  getShipmentCandidates(paymentCode: Payment['code'] | null): Shipment[] {
    const { shipments, isMailbinAvailable } = this._props
    const flags: { [key in Shipment['code']]: boolean } = {
      ikkatsu: true,
      mailbin: true,
      takuhai: true,
      cod: true,
      download: true
    }

    if (this.isIkkatsu) {
      flags.mailbin = false
      flags.takuhai = false
      flags.cod = false
    } else {
      flags.ikkatsu = false
    }

    if (paymentCode === 'cod') {
      flags.ikkatsu = false
      flags.takuhai = false
      flags.mailbin = false
    } else {
      flags.cod = false
    }

    if (!isMailbinAvailable) {
      flags.mailbin = false
    }

    if (this.isDownloadOnly) {
      flags.takuhai = false
    }

    return shipments.filter(shipment => flags[shipment.code])
  }
}

export const CartPropsFactory = (props?: Partial<ICartProps>): ICartProps => {
  return {
    subtotal: 2380,
    isDownloadable: false,
    isPrintable: true,
    isMailbinAvailable: true,
    payments: [
      {
        code: 'bank',
        feeAmount: 108
      }
    ],
    shipments: [
      {
        code: 'mailbin',
        feeAmount: 500
      }
    ],
    ikkatsu: null,
    selflabo: null,
    checkSum: 'askljnvaklga',
    downloadSetCartItemIds: [],
    ...props
  }
}

export const CartEntityFactory = (props?: Partial<ICartProps>): CartEntity => {
  return new CartEntity(CartPropsFactory(props))
}
