import Vue from 'vue'
import Router, { Route, RouteConfig } from 'vue-router'
import { MetaInfo } from 'vue-meta'

export interface RouteMeta {
  title?: string
  titleTemplate?: string
  breadCrumb?: {
    name: string
    parentName?: string
  }
  head?: MetaInfo
  hideHeaderMenu?: boolean
  showCartSideIcon?: boolean
  hideFooterMenu?: boolean
  hideAdSide?: boolean
  hideAdFoot?: boolean
  photoHeader?: boolean
  showChatBot?: boolean
  showNengaBannder?: boolean
  hideBreadCrumb?: boolean
}

interface Position {
  x: number
  y: number
}

function interopDefault<T extends typeof import('*.vue')>(promise: Promise<T>) {
  return promise.then(m => m.default || m)
}

const _ff5dd352 = () => interopDefault(import('../app/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _2522ab58 = () => interopDefault(import('../app/pages/certify.vue' /* webpackChunkName: "pages/certify" */))
const _2b80fcc2 = () => interopDefault(import('../app/pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _79cbda9b = () => interopDefault(import('../app/pages/family/index.vue' /* webpackChunkName: "pages/family/index" */))
const _bee6030a = () => interopDefault(import('../app/pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _034deae0 = () => interopDefault(import('../app/pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _79e187e5 = () => interopDefault(import('../app/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0f607d3e = () => interopDefault(import('../app/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _3e84fe51 = () => interopDefault(import('../app/pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _f9b5a454 = () => interopDefault(import('../app/pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _ff0f3b6c = () => interopDefault(import('../app/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _8ee2e766 = () => interopDefault(import('../app/pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _657434dc = () => interopDefault(import('../app/pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _60d288d8 = () => interopDefault(import('../app/pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _db0141f2 = () => interopDefault(import('../app/pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _23a05f4f = () => interopDefault(import('../app/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _2dc962e4 = () => interopDefault(import('../app/pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _31083fb6 = () => interopDefault(import('../app/pages/t-point/index.vue' /* webpackChunkName: "pages/t-point/index" */))
const _419ec974 = () => interopDefault(import('../app/pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _435bd617 = () => interopDefault(import('../app/pages/terms/transaction.vue' /* webpackChunkName: "pages/terms-transaction/index" */))
const _a9aef710 = () => interopDefault(import('../app/pages/voices/index.vue' /* webpackChunkName: "pages/voices/index" */))
const _fe55b894 = () => interopDefault(import('../app/pages/guide/about.vue' /* webpackChunkName: "pages/guide/about" */))
const _41299c39 = () => interopDefault(import('../app/pages/guide/beginner.vue' /* webpackChunkName: "pages/guide/beginner" */))
const _42e9a0ea = () => interopDefault(import('../app/pages/guide/cancellation.vue' /* webpackChunkName: "pages/guide/cancellation" */))
const _76c6620b = () => interopDefault(import('../app/pages/guide/family.vue' /* webpackChunkName: "pages/guide/family" */))
const _44578d6f = () => interopDefault(import('../app/pages/guide/payment.vue' /* webpackChunkName: "pages/guide/payment" */))
const _473f8bd8 = () => interopDefault(import('../app/pages/guide/product.vue' /* webpackChunkName: "pages/guide/product" */))
const _4f196795 = () => interopDefault(import('../app/pages/guide/shipping.vue' /* webpackChunkName: "pages/guide/shipping" */))
const _9f57d1f4 = () => interopDefault(import('../app/pages/guide/viewing.vue' /* webpackChunkName: "pages/guide/viewing" */))
const _f8848a36 = () => interopDefault(import('../app/pages/member/magazine.vue' /* webpackChunkName: "pages/member/magazine" */))
const _ebb80fd8 = () => interopDefault(import('../app/pages/member/signoff/index.vue' /* webpackChunkName: "pages/member/signoff/index" */))
const _5ba1b38e = () => interopDefault(import('../app/pages/member/t-point/index.vue' /* webpackChunkName: "pages/member/t-point/index" */))
const _298072e4 = () => interopDefault(import('../app/pages/order/complete.vue' /* webpackChunkName: "pages/order/complete" */))
const _0150aa62 = () => interopDefault(import('../app/pages/terms/coupon.vue' /* webpackChunkName: "pages/terms/coupon" */))
const _e5ebfa54 = () => interopDefault(import('../app/pages/terms/member.vue' /* webpackChunkName: "pages/terms/member" */))
const _5166c8a9 = () => interopDefault(import('../app/pages/terms/service.vue' /* webpackChunkName: "pages/terms/service" */))
const _2bc838c7 = () => interopDefault(import('../app/pages/cart/items/edit/index.vue' /* webpackChunkName: "pages/cart/items/edit/index" */))
const _5a9e9e6e = () => interopDefault(import('../app/pages/cart/items/resize.vue' /* webpackChunkName: "pages/cart/items/resize" */))
const _64167c8f = () => interopDefault(import('../app/pages/member/creditcard/edit.vue' /* webpackChunkName: "pages/member/creditcard/edit" */))
const _4d497ffe = () => interopDefault(import('../app/pages/member/email/edit.vue' /* webpackChunkName: "pages/member/email/edit" */))
const _3aa8757d = () => interopDefault(import('../app/pages/member/password/edit.vue' /* webpackChunkName: "pages/member/password/edit" */))
const _41a218fe = () => interopDefault(import('../app/pages/member/edit.vue' /* webpackChunkName: "pages/member/edit" */))
const _3397d522 = () => interopDefault(import('../app/pages/member/t-point/complete.vue' /* webpackChunkName: "pages/member/t-point/complete" */))
const _48cb95fb = () => interopDefault(import('../app/pages/member/t-point/uncomplete.vue' /* webpackChunkName: "pages/member/t-point/uncomplete" */))
const _6afc59ae = () => interopDefault(import('../app/pages/order/addresses/new.vue' /* webpackChunkName: "pages/order/addresses/new" */))
const _adc55030 = () => interopDefault(import('../app/pages/cart/items/edit/captions.vue' /* webpackChunkName: "pages/cart/items/edit/captions" */))
const _dd1735c8 = () => interopDefault(import('../app/pages/support/categories/_categoryId/index.vue' /* webpackChunkName: "pages/support/categories/_categoryId/index" */))
const _6692f321 = () => interopDefault(import('../app/pages/member/addresses/_addressId/delete.vue' /* webpackChunkName: "pages/member/addresses/_addressId/delete" */))
const _41f5d900 = () => interopDefault(import('../app/pages/member/addresses/_addressId/edit.vue' /* webpackChunkName: "pages/member/addresses/_addressId/edit" */))
const _03c9be4c = () => interopDefault(import('../app/pages/member/creditcard/delete.vue' /* webpackChunkName: "pages/member/creditcard/delete" */))
const _3ec69ba2 = () =>
  interopDefault(import('../app/pages/support/categories/_categoryId/sections/_sectionId/index.vue' /* webpackChunkName: "pages/support/categories/_categoryId/sections/_sectionId/index" */))
const _8717deb2 = () =>
  interopDefault(
    import(
      '../app/pages/support/categories/_categoryId/sections/_sectionId/contents/_contentId.vue' /* webpackChunkName: "pages/support/categories/_categoryId/sections/_sectionId/contents/_contentId" */
    )
  )
const _040302cb = () => interopDefault(import('../app/pages/events/_eventId/index.vue' /* webpackChunkName: "pages/events/_eventId/index" */))
const _6767d9b0 = () => interopDefault(import('../app/pages/orders/_orderId.vue' /* webpackChunkName: "pages/orders/_orderId" */))
const _281c2fff = () => interopDefault(import('../app/pages/events/_eventId/search.vue' /* webpackChunkName: "pages/events/_eventId/search" */))
const _3f91d00b = () => interopDefault(import('./pages/events/_eventId/uchinoko/index.vue' /* webpackChunkName: "pages/events/_eventId/uchinoko/index" */))
const _e84fc69c = () => interopDefault(import('../app/pages/families/_groupId/breakup/index.vue' /* webpackChunkName: "pages/families/_groupId/breakup/index" */))
const _5ee78c94 = () => interopDefault(import('../app/pages/families/_groupId/invitation.vue' /* webpackChunkName: "pages/families/_groupId/invitation" */))
const _089b3036 = () => interopDefault(import('../app/pages/families/_groupId/join.vue' /* webpackChunkName: "pages/families/_groupId/join" */))
const _d6ef3b6a = () => interopDefault(import('../app/pages/families/_groupId/children/new.vue' /* webpackChunkName: "pages/families/_groupId/children/new" */))
const _1121df63 = () => interopDefault(import('../app/pages/families/_groupId/name/edit.vue' /* webpackChunkName: "pages/families/_groupId/name/edit" */))
const _8d6bf1de = () => interopDefault(import('../app/pages/families/_groupId/schoolpass/new.vue' /* webpackChunkName: "pages/families/_groupId/schoolpass/new" */))
const _6e8c5235 = () => interopDefault(import('../app/pages/families/_groupId/settings/uchinoko.vue' /* webpackChunkName: "pages/families/_groupId/settings/uchinoko" */))
const _16247d58 = () => interopDefault(import('../app/pages/events/_eventId/albums/_albumId/photos/index.vue' /* webpackChunkName: "pages/events/_eventId/albums/_albumId/photos/index" */))
const _2a40d7e8 = () => interopDefault(import('../app/pages/families/_groupId/children/_childId/edit.vue' /* webpackChunkName: "pages/families/_groupId/children/_childId/edit" */))
const _83c641f4 = () => interopDefault(import('../app/pages/families/_groupId/children/_childId/remove.vue' /* webpackChunkName: "pages/families/_groupId/children/_childId/remove" */))
const _7c2cd5f6 = () =>
  interopDefault(import('../app/pages/families/_groupId/invitations/_groupInvitationId/edit.vue' /* webpackChunkName: "pages/families/_groupId/invitations/_groupInvitationId/edit" */))
const _4b3e65f6 = () =>
  interopDefault(import('../app/pages/families/_groupId/invitations/_groupInvitationId/resend.vue' /* webpackChunkName: "pages/families/_groupId/invitations/_groupInvitationId/resend" */))
const _63875203 = () => interopDefault(import('../app/pages/families/_groupId/members/_groupMemberId/edit.vue' /* webpackChunkName: "pages/families/_groupId/members/_groupMemberId/edit" */))
const _a8660986 = () => interopDefault(import('../app/pages/families/_groupId/members/_groupMemberId/remove.vue' /* webpackChunkName: "pages/families/_groupId/members/_groupMemberId/remove" */))
const _497c50c2 = () =>
  interopDefault(import('../app/pages/events/_eventId/albums/_albumId/photos/_photoId/index.vue' /* webpackChunkName: "pages/events/_eventId/albums/_albumId/photos/_photoId/index" */))
const _4305fa36 = () =>
  interopDefault(
    import('../app/pages/events/_eventId/albums/_albumId/photos/_photoId/request-unpublish.vue' /* webpackChunkName: "pages/events/_eventId/albums/_albumId/photos/_photoId/request-unpublish" */)
  )
const _9f8c3664 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}

// TODO: scrollBehaviorの書き方を変える
// https://github.com/nuxt/nuxt.js/pull/6055
// eslint-disable-next-line
const scrollBehavior = function (to: Route, from: Route, savedPosition: Position | void): any {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position: Position | boolean | { selector: string } = false

  // if no children detected and scrollToTop is not explicitly disabled
  // @ts-ignore
  if (to.matched.length < 2 && to.matched.every(r => r.components.default.options.scrollToTop !== false)) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
    // @ts-ignore
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise(resolve => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export const routes: (Omit<RouteConfig, 'meta'> & { meta?: RouteMeta })[] = [
  {
    path: '/cart',
    component: _ff5dd352,
    name: 'cart',
    meta: {
      title: 'カート',
      breadCrumb: {
        name: 'カート'
      },
      hideAdSide: true,
      hideAdFoot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/certify',
    component: _2522ab58,
    name: 'certify',
    meta: {
      title: '会員登録完了',
      breadCrumb: {
        name: '会員登録完了'
      },
      hideAdSide: true,
      hideAdFoot: true,
      hideHeaderMenu: true,
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/contact',
    component: _2b80fcc2,
    name: 'contact',
    meta: {
      title: 'お問い合わせ',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スナップスナップに関するご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'スナップスナップに関するご不明点はお気軽にお問い合わせください。' }
        ]
      },
      breadCrumb: {
        name: 'お問い合わせ'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/family',
    component: _79cbda9b,
    name: 'family',
    meta: {
      title: 'ファミリーイベント一覧',
      hideAdSide: true,
      showNengaBannder: false
    }
  },
  {
    path: '/guide',
    component: _bee6030a,
    name: 'guide',
    meta: {
      title: 'ご利用ガイド'
    }
  },
  {
    path: '/guide/moovin',
    component: () => interopDefault(import('../app/pages/guide/moovin.vue' /* webpackChunkName: "pages/guide/moovin" */)),
    name: 'guide-moovin',
    meta: {
      title: 'moovinについて',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'moovinについて | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。' },
          { hid: 'og:description', property: 'og:description', content: 'moovinについて | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。' }
        ]
      },
      breadCrumb: {
        name: 'moovinについて'
      },
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/invitation',
    component: _034deae0,
    name: 'invitation',
    meta: {
      title: 'ファミリーへの招待',
      breadCrumb: {
        name: 'ファミリーへの招待'
      },
      hideHeaderMenu: true,
      showChatBot: true
    }
  },
  {
    path: '/invitation/error',
    component: () => interopDefault(import('../app/pages/invitation/error.vue' /* webpackChunkName: "pages/invitation/error" */)),
    name: 'invitation-error',
    meta: {
      title: 'ファミリー参加認証エラー',
      breadCrumb: {
        name: 'ファミリー参加認証エラー'
      },
      showChatBot: true
    }
  },
  {
    path: '/login',
    component: _79e187e5,
    name: 'login',
    meta: {
      title: 'ログイン',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'スナップスナップの会員ログインはこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'スナップスナップの会員ログインはこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      },
      breadCrumb: {
        name: 'ログイン'
      },
      hideAdSide: true,
      hideAdFoot: true,
      hideHeaderMenu: true,
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/logout',
    component: _0f607d3e,
    name: 'logout',
    meta: {
      title: 'ログアウトしました',
      breadCrumb: {
        name: 'ログアウト'
      },
      hideHeaderMenu: true,
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/member',
    component: _3e84fe51,
    name: 'member',
    meta: {
      title: '会員情報設定',
      breadCrumb: {
        name: '会員情報設定'
      },
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/menu',
    component: _f9b5a454,
    name: 'menu',
    meta: {
      title: 'ファミリー情報設定',
      breadCrumb: {
        name: 'ファミリー情報設定'
      },
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/news',
    component: _ff0f3b6c,
    name: 'news',
    meta: {
      title: 'お知らせ',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スクールフォト販売サイト スナップスナップの新着情報です。' },
          { hid: 'og:description', property: 'og:description', content: 'スクールフォト販売サイト スナップスナップの新着情報です。' }
        ]
      },
      breadCrumb: {
        name: 'お知らせ一覧'
      },
      hideHeaderMenu: true,
      hideAdSide: true
    }
  },
  {
    path: '/news/:id',
    component: () => interopDefault(import('../app/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */)),
    name: 'news-id',
    meta: {
      title: 'お知らせ',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スクールフォト販売サイト スナップスナップの新着情報です。' },
          { hid: 'og:description', property: 'og:description', content: 'スクールフォト販売サイト スナップスナップの新着情報です。' }
        ]
      },
      breadCrumb: {
        name: 'お知らせ',
        parentName: 'news'
      },
      hideHeaderMenu: true
    }
  },
  {
    path: '/order',
    component: _8ee2e766,
    name: 'order',
    meta: {
      breadCrumb: {
        name: 'お届け先選択'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/paypay/before',
    component: () => interopDefault(import('../app/pages/paypay/before/index.vue')),
    name: 'paypay-before',
    meta: {
      title: 'PayPayでのお支払い手続きをお済ませください',
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true,
      hideBreadCrumb: true
    }
  },
  {
    path: '/paypay/:orderId/payment_detail',
    component: () => interopDefault(import('../app/pages/paypay/_orderId/payment_detail/index.vue')),
    name: 'paypay-payment-detail',
    meta: {
      title: 'PayPay注文処理中',
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      hideBreadCrumb: true
    }
  },
  {
    path: '/paypay/:orderId/payment_detail/error',
    component: () => interopDefault(import('../app/pages/paypay/_orderId/payment_detail/error.vue')),
    name: 'paypay-error',
    meta: {
      title: '注文に失敗しました'
    }
  },
  {
    path: '/order/t-point-error',
    component: () => interopDefault(import('../app/pages/order/t-point-error.vue' /* webpackChunkName: "pages/order/t-point-error" */)),
    name: 'order-t-point-error',
    meta: {
      title: '',
      breadCrumb: {
        name: 'Vポイント連携エラー'
      }
    }
  },
  {
    path: '/orders',
    component: _657434dc,
    name: 'orders',
    meta: {
      title: '注文履歴',
      breadCrumb: {
        name: '注文履歴'
      },
      showChatBot: true
    }
  },
  {
    path: '/password-reset',
    component: _60d288d8,
    name: 'password-reset',
    meta: {
      title: 'パスワードの再設定',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'スナップスナップの会員ログインパスワードの再設定はこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'スナップスナップの会員ログインパスワードの再設定はこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      },
      breadCrumb: {
        name: 'パスワードの再設定'
      },
      hideAdSide: true,
      hideAdFoot: true,
      hideHeaderMenu: true,
      hideFooterMenu: true,
      showChatBot: true
    }
  },
  {
    path: '/privacy',
    component: _db0141f2,
    name: 'privacy',
    meta: {
      title: 'プライバシーポリシー',
      breadCrumb: {
        name: 'プライバシーポリシー'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/signup',
    component: _23a05f4f,
    name: 'signup',
    meta: {
      title: '初めてご利用の方へ',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'スナップスナップを初めて利用する方はこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'スナップスナップを初めて利用する方はこちらから。スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      },
      breadCrumb: {
        name: '初めてご利用の方へ'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/support',
    component: _2dc962e4,
    name: 'support',
    meta: {
      title: 'よくある質問（お問い合わせ）',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' }
        ]
      },
      breadCrumb: {
        name: 'お問い合わせ'
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/support/search',
    component: () => interopDefault(import('../app/pages/support/search/index.vue' /* webpackChunkName: "pages/support/search" */)),
    name: 'support-search',
    meta: {
      title: 'よくある質問の検索結果',
      breadCrumb: {
        name: 'よくある質問の検索結果',
        parentName: 'support'
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/t-point',
    component: _31083fb6,
    name: 't-point',
    meta: {
      title: 'Vポイント利用手続き',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スナップスナップではVポイントが使えます。' },
          { hid: 'og:description', property: 'og:description', content: 'スナップスナップではVポイントが使えます。' }
        ]
      },
      breadCrumb: {
        name: 'Vポイント利用手続き'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true
    }
  },
  {
    path: '/terms',
    component: _419ec974,
    name: 'terms',
    meta: {
      title: '規約',
      breadCrumb: {
        name: '規約'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/terms/transaction',
    component: _435bd617,
    name: 'terms-transaction',
    meta: {
      title: '特定商取引法に基づく表示',
      breadCrumb: {
        name: '特定商取引法に基づく表示'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/voices',
    component: _a9aef710,
    name: 'voices',
    meta: {
      title: 'お客さまの声・口コミ',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スクールフォト販売サイト スナップスナップを利用したお客さまから、ご意見・ご感想をいただきました。' },
          { hid: 'og:description', property: 'og:description', content: 'スクールフォト販売サイト スナップスナップを利用したお客さまから、ご意見・ご感想をいただきました。' }
        ]
      },
      breadCrumb: {
        name: 'お客さまの声・口コミ'
      },
      hideHeaderMenu: true,
      hideAdSide: true
    }
  },
  {
    path: '/voices/:id',
    component: () => interopDefault(import('../app/pages/voices/_id.vue' /* webpackChunkName: "pages/voices/index/_id" */)),
    name: 'voices-id',
    meta: {
      title: 'お客さまの声・口コミ',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'スクールフォト販売サイト スナップスナップを利用したお客さまから、ご意見・ご感想をいただきました。' },
          { hid: 'og:description', property: 'og:description', content: 'スクールフォト販売サイト スナップスナップを利用したお客さまから、ご意見・ご感想をいただきました。' }
        ]
      },
      breadCrumb: {
        name: 'お客さまの声・口コミ',
        parentName: 'voices'
      },
      hideHeaderMenu: true
    }
  },
  {
    path: '/families/new',
    component: () => interopDefault(import('../app/pages/families/new.vue' /* webpackChunkName: "pages/families/new/index" */)),
    name: 'families-new',
    meta: {
      title: 'ファミリー作成',
      hideHeaderMenu: true,
      hideAdSide: true
    }
  },
  {
    path: '/families/registered',
    component: () => interopDefault(import('../app/pages/families/registered.vue')),
    name: 'families-registered',
    meta: {
      title: 'スクールパスを登録しよう',
      hideHeaderMenu: true,
      hideAdSide: true
    }
  },
  {
    path: '/guide/about',
    component: _fe55b894,
    name: 'guide-about',
    meta: {
      title: 'スナップスナップについて | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              'スクール（園・学校）のイベントをカメラマンが撮影します。スマートフォンやパソコンでスナップスナップにアクセスし、写真をみる・買うことができます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              'スクール（園・学校）のイベントをカメラマンが撮影します。スマートフォンやパソコンでスナップスナップにアクセスし、写真をみる・買うことができます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/beginner',
    component: _41299c39,
    name: 'guide-beginner',
    meta: {
      title: '初めての方 | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              'スナップスナップを初めて利用するには会員登録が必要です。会員登録は初回だけでOK。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              'スナップスナップを初めて利用するには会員登録が必要です。会員登録は初回だけでOK。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/cancellation',
    component: _42e9a0ea,
    name: 'guide-cancellation',
    meta: {
      title: 'キャンセル・返金 | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              'キャン受注生産品のため、キャンセルを承ることはできかねます。ただし、写真の品質に問題がある場合は、以下の「よくある質問 - 返品について」をご確認ください。セル・返金 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              'キャン受注生産品のため、キャンセルを承ることはできかねます。ただし、写真の品質に問題がある場合は、以下の「よくある質問 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/family',
    component: _76c6620b,
    name: 'guide-family',
    meta: {
      title: 'ファミリー共有を楽しむ | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              '家族を招待して共有ができます。おじいちゃん、おばあちゃんを招待してみませんか。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              '家族を招待して共有ができます。おじいちゃん、おばあちゃんを招待してみませんか。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/payment',
    component: _44578d6f,
    name: 'guide-payment',
    meta: {
      title: '支払い・手数料・Vポイント | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: '以下の支払い方法がご利用できます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: '以下の支払い方法がご利用できます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/product',
    component: _473f8bd8,
    name: 'guide-product',
    meta: {
      title: '商品の料金・種類 | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'イベント・写真・期間ごとに料金・商品展開が異なります。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'イベント・写真・期間ごとに料金・商品展開が異なります。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/shipping',
    component: _4f196795,
    name: 'guide-shipping',
    meta: {
      title: '配送・手数料・お届け | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'イベント・注文内容ごとに選べる配送方法が異なる場合があります。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'イベント・注文内容ごとに選べる配送方法が異なる場合があります。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/guide/viewing',
    component: _9f57d1f4,
    name: 'guide-viewing',
    meta: {
      title: '写真をみる・買う | ご利用ガイド',
      head: {
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              '会員登録とスクールパスの設定が済めば、その次からはログインでOK。イベントごとに写真がみられます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              '会員登録とスクールパスの設定が済めば、その次からはログインでOK。イベントごとに写真がみられます。 | スナップスナップは、こどもたちの生き生きとした瞬間を『カタチ＝写真』にしてお届けするサービスです。'
          }
        ]
      }
    }
  },
  {
    path: '/member/magazine',
    component: _f8848a36,
    name: 'member-magazine',
    meta: {
      title: 'メール購読設定変更',
      breadCrumb: {
        name: 'メール購読設定変更',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/signoff',
    component: _ebb80fd8,
    name: 'member-signoff',
    meta: {
      title: '退会確認',
      breadCrumb: {
        name: '退会確認',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/t-point',
    component: _5ba1b38e,
    name: 'member-t-point',
    meta: {
      title: 'Vポイント利用手続き',
      breadCrumb: {
        name: 'Vポイント利用手続き',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/order/complete',
    component: _298072e4,
    name: 'order-complete',
    meta: {
      title: 'ご注文完了',
      breadCrumb: {
        name: 'ご注文完了'
      },
      showNengaBannder: false
    }
  },
  {
    path: '/terms/coupon',
    component: _0150aa62,
    name: 'terms-coupon',
    meta: {
      title: 'お買い物クーポン利用規約',
      breadCrumb: {
        name: 'お買い物クーポン利用規約',
        parentName: 'terms'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/terms/member',
    component: _e5ebfa54,
    name: 'terms-member',
    meta: {
      title: '会員サービス利用規約',
      breadCrumb: {
        name: '会員サービス利用規約',
        parentName: 'terms'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/terms/service',
    component: _5166c8a9,
    name: 'terms-service',
    meta: {
      title: 'サービス利用基本規約',
      breadCrumb: {
        name: 'サービス利用基本規約',
        parentName: 'terms'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/cart/items/edit',
    component: _2bc838c7,
    name: 'cart-items-edit',
    meta: {
      title: '写真の編集',
      breadCrumb: {
        name: '写真の編集',
        parentName: 'cart'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/cart/items/resize',
    component: _5a9e9e6e,
    name: 'cart-items-resize',
    meta: {
      title: 'サイズをまとめて変更',
      breadCrumb: {
        name: 'サイズをまとめて変更',
        parentName: 'cart'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/cart/items/option',
    component: () => interopDefault(import('../app/pages/cart/items/option.vue' /* webpackChunkName: "pages/cart/items/option" */)),
    name: 'cart-items-option',
    meta: {
      title: 'ダウンロードオプション',
      breadCrumb: {
        name: 'ダウンロードオプション',
        parentName: 'cart'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/member/addresses/new',
    component: () => interopDefault(import('../app/pages/member/addresses/new.vue' /* webpackChunkName: "pages/member/addresses/new" */)),
    name: 'member-addresses-new',
    meta: {
      title: 'お届け先情報登録',
      breadCrumb: {
        name: 'お届け先情報登録',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/creditcard/edit',
    component: _64167c8f,
    name: 'member-creditcard-edit',
    meta: {
      title: 'クレジットカード登録',
      breadCrumb: {
        name: 'クレジットカード登録',
        parentName: 'member'
      }
    }
  },
  {
    path: '/member/email/reset',
    component: () => interopDefault(import('../app/pages/member/email/reset.vue' /* webpackChunkName: "pages/member/email/reset" */)),
    name: 'member-email-reset',
    meta: {
      title: 'メールアドレス変更確認',
      breadCrumb: {
        name: 'メールアドレス変更確認',
        parentName: 'member'
      }
    }
  },
  {
    path: '/member/email/edit',
    component: _4d497ffe,
    name: 'member-email-edit',
    meta: {
      title: 'メールアドレス変更',
      breadCrumb: {
        name: 'メールアドレス変更',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/password/edit',
    component: _3aa8757d,
    name: 'member-password-edit',
    meta: {
      title: 'パスワード変更',
      breadCrumb: {
        name: 'パスワード変更',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/edit',
    component: _41a218fe,
    name: 'member-edit',
    meta: {
      title: 'プロフィール設定変更',
      breadCrumb: {
        name: 'プロフィール設定変更',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/t-point/complete',
    component: _3397d522,
    name: 'member-t-point-complete',
    meta: {
      title: 'Vポイント利用手続き完了',
      breadCrumb: {
        name: 'Vポイント利用手続き完了',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/t-point/uncomplete',
    component: _48cb95fb,
    name: 'member-t-point-uncomplete',
    meta: {
      title: 'Vポイント利用手続きエラー',
      breadCrumb: {
        name: 'Vポイント利用手続きエラー',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/order/addresses/new',
    component: _6afc59ae,
    name: 'order-addresses-new',
    meta: {
      title: 'お客さま情報入力',
      breadCrumb: {
        name: 'お客さま情報入力'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/cart/items/edit/captions',
    component: _adc55030,
    name: 'cart-items-edit-captions',
    meta: {
      title: '写真を選択（まとめて文字入れ）',
      breadCrumb: {
        name: '写真を選択（まとめて文字入れ）',
        parentName: 'cart-items-edit'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/support/contents/:contentId',
    component: () => interopDefault(import('../app/pages/support/contents/_contentId.vue' /* webpackChunkName: "pages/support/contents/_contentId" */)),
    name: 'support-contents-contentId',
    meta: {
      title: 'よくある質問',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' }
        ]
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/support/categories/:categoryId',
    component: _dd1735c8,
    name: 'support-categories-categoryId',
    meta: {
      title: 'よくある質問',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' }
        ]
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/member/addresses/:addressId/delete',
    component: _6692f321,
    name: 'member-addresses-addressId-delete',
    meta: {
      title: 'お届け先情報削除確認',
      breadCrumb: {
        name: 'お届け先情報削除確認',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/addresses/:addressId/edit',
    component: _41f5d900,
    name: 'member-addresses-addressId-edit',
    meta: {
      title: 'お届け先情報変更',
      breadCrumb: {
        name: 'お届け先情報変更',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/member/creditcard/delete',
    component: _03c9be4c,
    name: 'member-creditcard-delete',
    meta: {
      title: 'クレジットカード情報削除確認',
      breadCrumb: {
        name: 'クレジットカード情報削除確認',
        parentName: 'member'
      },
      showChatBot: true
    }
  },
  {
    path: '/support/categories/:categoryId/sections/:sectionId',
    component: _3ec69ba2,
    name: 'support-categories-categoryId-sections-sectionId',
    meta: {
      title: 'よくある質問',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' }
        ]
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/support/categories/:categoryId/sections/:sectionId/contents/:contentId',
    component: _8717deb2,
    name: 'support-categories-categoryId-sections-sectionId-contents-contentId',
    // TODO: イレギュラーケース 末端がcontentIdによって可変する
    // お問い合わせ ＞ 初めてご利用の方 ＞ 会員登録について ＞ 新規会員登録をすると、「登録済みまたは利用できないアドレスです。」というエラーが出る場合は？
    meta: {
      title: 'よくある質問',
      head: {
        meta: [
          { hid: 'description', name: 'description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' },
          { hid: 'og:description', property: 'og:description', content: 'お客さまからよくいただくご質問と回答をご紹介します。ご不明点はお気軽にお問い合わせください。' }
        ]
      },
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true
    }
  },
  {
    path: '/events/:eventId',
    component: _040302cb,
    name: 'events-eventId',
    meta: {
      title: 'イベントトップ',
      breadCrumb: {
        name: 'イベントトップ'
      },
      showNengaBannder: false
    }
  },
  {
    path: '/orders/:orderId',
    component: _6767d9b0,
    name: 'orders-orderId',
    meta: {
      title: '注文詳細',
      breadCrumb: {
        name: '注文詳細',
        parentName: 'orders'
      },
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '/orders/:orderId/download',
    component: () => interopDefault(import('../app/pages/orders/_orderId/download.vue' /* webpackChunkName: "pages/orders/_orderId/download" */)),
    name: 'orders-orderId-download',
    meta: {
      title: '注文詳細',
      breadCrumb: {
        name: '注文詳細（ダウンロード）',
        parentName: 'orders-orderId'
      }
    }
  },
  {
    path: '/events/:eventId/search',
    component: _281c2fff,
    name: 'events-eventId-search',
    meta: {
      title: '写真番号検索結果',
      breadCrumb: {
        name: '写真番号検索結果',
        parentName: 'events-eventId'
      },
      hideAdSide: true,
      showCartSideIcon: true,
      showNengaBannder: false
    }
  },
  {
    path: '/events/:eventId/uchinoko',
    component: _3f91d00b,
    name: 'events-eventId-uchinoko',
    meta: {
      title: 'かお検索結果（おすすめ）',
      breadCrumb: {
        name: 'かお検索結果（おすすめ）',
        parentName: 'events-eventId'
      },
      hideAdSide: true,
      photoHeader: true,
      showCartSideIcon: true,
      showNengaBannder: false
    }
  },
  {
    path: '/events/:eventId/uchinoko/extra',
    component: () => interopDefault(import('./pages/events/_eventId/uchinoko/extra.vue' /* webpackChunkName: "pages/events/_eventId/uchinoko/extra" */)),
    name: 'events-eventId-uchinoko-extra',
    meta: {
      title: 'かお検索結果（追加）',
      breadCrumb: {
        name: 'かお検索結果（追加）',
        parentName: 'events-eventId-uchinoko'
      },
      hideAdSide: true,
      photoHeader: true,
      showCartSideIcon: true,
      showNengaBannder: false
    }
  },
  {
    path: '/families/:groupId/breakup',
    component: _e84fc69c,
    name: 'families-groupId-breakup',
    meta: {
      title: 'ファミリー解散',
      breadCrumb: {
        name: 'ファミリー解散',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/invitation',
    component: _5ee78c94,
    name: 'families-groupId-invitation',
    meta: {
      title: 'ファミリーメンバー招待',
      breadCrumb: {
        name: 'ファミリーメンバー招待',
        parentName: 'menu'
      },
      hideAdSide: true,
      hideAdFoot: true,
      hideHeaderMenu: true,
      hideFooterMenu: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/join',
    component: _089b3036,
    name: 'families-groupId-join',
    meta: {
      title: 'ファミリー参加確認',
      breadCrumb: {
        name: 'ファミリー参加確認',
        parentName: 'invitation'
      },
      hideHeaderMenu: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/children/new',
    component: _d6ef3b6a,
    name: 'families-groupId-children-new',
    meta: {
      title: 'お子さま情報追加',
      breadCrumb: {
        name: 'お子さま情報追加',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/children/confirm',
    component: () => interopDefault(import('../app/pages/families/_groupId/children/confirm.vue')),
    name: 'families-groupId-children-confirm',
    meta: {
      title: 'お子さま情報追加確認',
      breadCrumb: {
        name: 'お子さま情報追加確認',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/children/complete',
    component: () => interopDefault(import('../app/pages/families/_groupId/children/complete.vue')),
    name: 'families-groupId-children-complete',
    meta: {
      title: 'お子さま情報追加完了',
      breadCrumb: {
        name: 'お子さま情報追加完了',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/name/edit',
    component: _1121df63,
    name: 'families-groupId-name-edit',
    meta: {
      title: 'ファミリー名編集',
      breadCrumb: {
        name: 'ファミリー名編集',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/schoolpass/new',
    component: _8d6bf1de,
    name: 'families-groupId-schoolpass-new',
    meta: {
      title: 'スクールパス登録',
      breadCrumb: {
        name: 'スクールパス登録',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/schoolpass/confirm',
    component: () => interopDefault(import('../app/pages/families/_groupId/schoolpass/confirm.vue')),
    name: 'families-groupId-schoolpass-confirm',
    meta: {
      title: 'スクールパス登録確認',
      breadCrumb: {
        name: 'スクールパス登録確認',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/schoolpass/complete',
    component: () => interopDefault(import('../app/pages/families/_groupId/schoolpass/complete.vue')),
    name: 'families-groupId-schoolpass-complete',
    meta: {
      title: 'スクールパス登録完了',
      breadCrumb: {
        name: 'スクールパス登録完了',
        parentName: 'menu'
      },
      hideHeaderMenu: true,
      hideFooterMenu: true,
      hideAdSide: true,
      hideAdFoot: true,
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/settings/uchinoko',
    component: _6e8c5235,
    name: 'families-groupId-settings-uchinoko',
    meta: {
      title: 'かお検索設定',
      breadCrumb: {
        name: 'かお検索設定',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/events/:eventId/albums/:albumId/photos',
    component: _16247d58,
    name: 'events-eventId-albums-albumId-photos',
    meta: {
      title: '写真一覧',
      breadCrumb: {
        name: '写真一覧',
        parentName: 'events-eventId'
      },
      hideAdSide: true,
      photoHeader: true,
      showCartSideIcon: true,
      showNengaBannder: false
    }
  },
  {
    path: '/families/:groupId/children/:childId/edit',
    component: _2a40d7e8,
    name: 'families-groupId-children-childId-edit',
    meta: {
      title: 'お子さま情報変更',
      breadCrumb: {
        name: 'お子さま情報変更',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/children/:childId/confirm',
    component: () => interopDefault(import('../app/pages/families/_groupId/children/_childId/confirm.vue')),
    name: 'families-groupId-children-childId-confirm',
    meta: {
      title: 'お子さま情報変更確認',
      breadCrumb: {
        name: 'お子さま情報変更確認',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/children/:childId/remove',
    component: _83c641f4,
    name: 'families-groupId-children-childId-remove',
    meta: {
      title: 'お子さま情報削除',
      breadCrumb: {
        name: 'お子さま情報削除',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/invitations/:groupInvitationId/edit',
    component: _7c2cd5f6,
    name: 'families-groupId-invitations-groupInvitationId-edit',
    meta: {
      title: 'ファミリーメンバー編集',
      breadCrumb: {
        name: 'ファミリーメンバー編集',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/invitations/:groupInvitationId/resend',
    component: _4b3e65f6,
    name: 'families-groupId-invitations-groupInvitationId-resend',
    meta: {
      title: '招待メール再送信確認',
      breadCrumb: {
        name: '招待メール再送信確認',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/members/:groupMemberId/edit',
    component: _63875203,
    name: 'families-groupId-members-groupMemberId-edit',
    meta: {
      title: 'ファミリーメンバー編集',
      breadCrumb: {
        name: 'ファミリーメンバー編集',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/families/:groupId/members/:groupMemberId/remove',
    component: _a8660986,
    name: 'families-groupId-members-groupMemberId-remove',
    meta: {
      title: 'ファミリーメンバー解除確認',
      breadCrumb: {
        name: 'ファミリーメンバー解除確認',
        parentName: 'menu'
      },
      showChatBot: true
    }
  },
  {
    path: '/events/:eventId/albums/:albumId/photos/:photoId',
    component: _497c50c2,
    name: 'events-eventId-albums-albumId-photos-photoId',
    meta: {
      hideAdSide: true
    }
    // TODO: パンくずなし
  },
  {
    path: '/events/:eventId/albums/:albumId/photos/:photoId/request-unpublish',
    component: _4305fa36,
    name: 'events-eventId-albums-albumId-photos-photoId-request-unpublish',
    meta: {
      title: '非掲載依頼',
      breadCrumb: {
        name: '非掲載依頼',
        parentName: 'events-eventId'
      },
      hideAdSide: true
    }
  },
  {
    path: '/ylogin/callback',
    component: () => interopDefault(import('../app/pages/ylogin/callback.vue' /* webpackChunkName: "pages/ylogin/callback" */)),
    name: 'ylogin-callback'
  },
  {
    path: '/',
    component: _9f8c3664,
    name: 'index',
    meta: {
      titleTemplate: '',
      showChatBot: true,
      showNengaBannder: false
    }
  },
  {
    path: '*', // 存在しないpath（notFound)は広告を表示しない
    meta: {
      hideAdSide: true,
      hideAdFoot: true
    }
  }
]

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,
    routes,
    fallback: false
  })
}
